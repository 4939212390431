


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { Location } from 'vue-router';
import IconCloseQrPopup from '@/_modules/icons/components/qrpopup/icon-close-qr-popup.vue';
import { TContactTag } from '@/_types/contact-tag.type';
import searchBar from '@/_components/search-bar/search-bar.vue';
import contacts from '@/_modules/contacts/components/contacts/contacts.vue';
import Tabs from '@/_ui/tabs/tabs.vue';
import { ContactsFilterType, TContactsFilter } from '@/_modules/contacts/types/contacts-filter.type';
import { TContact } from '@/_types/contact.type';
import IconArrowLeft from '@/_modules/icons/components/icon-arrow-left.vue';
import { Getter } from 'vuex-class';
import { TTab } from '@/_ui/tabs/types/tabs.type';
import {TPromoPage} from '@/_types/promo-page/promo-page.type';
import CompaniesListItem from '@/_modules/promo-hall/components/companies-list-item/companies-list-item.vue';

type TLocalContactTag = {
  tag: TContactTag;
  isSelected: boolean;
};

@Component({
  components: {
    IconCloseQrPopup,
    searchBar,
    contacts,
    Tabs,
    IconArrowLeft,
    CompaniesListItem,
  }
})
export default class ContactsViewer extends Vue {

  @Getter('_eventStore/eventContactTags') eventContactTags: TContactTag[];
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('promoStore/promoPageByExternalId') public readonly companyByExternalId: (externalId: string) => TPromoPage;

  public isTagsSelectorPopupVisible: boolean = false;
  public eventContactTagsLocal: TLocalContactTag[] = [];

  public tabList: TTab[] = [
    {
      title: this.$t('contacts.allContactsTab'),
      isActive: this.$route.query.type === 'all',
      rightTopBadge: null,
      index: 0,
    },
    {
      title: this.$t('contacts.myContactsTab'),
      isActive: this.$route.query.type === 'my',
      rightTopBadge: null,
      index: 1,
    }
  ];

  @Watch('eventContactTags', { immediate: true })
  private onEventContactTagsChange(): void {
    this.initEventContactTagsLocal();
  }

  public get isFilterSearchDisabled(): boolean {
    return this.queryType === 'my';
  }

  public get queryType(): 'all' | 'my' {
    return this.$route.query.type === 'my' ? 'my' : 'all';
  }

  public get querySearch(): string {
    return ('' + (this.$route.query.search || '')).trim();
  }

  public get queryTagIds(): number[] {
    if (typeof this.$route.query.tags === 'string') {
      return [parseInt(this.$route.query.tags, 10)];
    } else if (Array.isArray(this.$route.query.tags)) {
      return (this.$route.query.tags as string[]).map(item => parseInt(item, 10));
    }
    return [];
  }

  public get menuLocationTypeAll(): Location {
    return {
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        type: undefined,
      }),
    };
  }

  public get menuLocationTypeMy(): Location {
    return {
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        type: 'my',
      }),
    };
  }

  public get isPromoPageNavigationInvisible(): boolean {
    return this.filter.type === ContactsFilterType.BOOTH_VIEWERS;
  }

  public get filter(): TContactsFilter {
    let tagIds: number[] = [];
    if (typeof this.$route.query.tags === 'string') {
      tagIds = [parseInt(this.$route.query.tags, 10)];
    } else if (Array.isArray(this.$route.query.tags)) {
      tagIds = (this.$route.query.tags as string[]).map(item => parseInt(item, 10));
    }

    let type: ContactsFilterType;
    switch (this.$route.query.type) {
      case ContactsFilterType.BOOTH_VIEWERS:
      case ContactsFilterType.MY:
        type = this.$route.query.type;
        break;
      default:
        type = ContactsFilterType.ALL;
    }

    return {
      type: type,
      search: ('' + (this.$route.query.search || '')).trim(),
      tagIds: tagIds,
    };
  }

  public mounted(): void {
    this.initContactsSearchBarTabs();
  }

  public initContactsSearchBarTabs(): void {
    if (this.$route.query.type === ContactsFilterType.BOOTH_VIEWERS) {
      return;
    }
    this.queryType === 'my' ? this.activeTab(this.tabList[1], 1) : this.activeTab(this.tabList[0], 0);
  }

  public get companyForBoothViewers(): TPromoPage {
    const isBoothViewersParamPresent: boolean = this.$route.query.type === ContactsFilterType.BOOTH_VIEWERS;
    const boothExternalId: string = this.$route.query.boothExternalId as string;
    if (!isBoothViewersParamPresent || (!boothExternalId && boothExternalId !== '0')) {
      return null;
    }
    return this.companyByExternalId(boothExternalId);
  }

  public onTagsFilterClick(): void {
    if (this.isFilterSearchDisabled) {
      this.isTagsSelectorPopupVisible = false;
      return;
    }
    this.isTagsSelectorPopupVisible = !this.isTagsSelectorPopupVisible;
  }

  public closeContactTagsSelectorPopup(): void {
    this.isTagsSelectorPopupVisible = false;
  }

  public toggleContactTagSelection(localContactTag: TLocalContactTag): void {
    localContactTag.isSelected = !localContactTag.isSelected;
    const tagIds = this.eventContactTagsLocal
      .filter(item => item.isSelected)
      .map(item => item.tag.id);

    this.$router.push({
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        tags: tagIds.length > 0 ? tagIds : undefined,
      }),
    });
  }

  public clearSelectedContactTags(): void {
    this.$router.push({
      name: this.$route.name,
      params: this.$route.params,
      query: Object.assign({}, this.$route.query, {
        tags: undefined,
      }),
    });
    this.initEventContactTagsLocal();
    this.closeContactTagsSelectorPopup();
  }

  public onSearchClick(value: string): void {
    if (this.isFilterSearchDisabled) {
      return;
    }
    const searchString = (value || '').trim();
    if (searchString !== this.querySearch) {
      this.$router.push({
        name: this.$route.name,
        params: this.$route.params,
        query: Object.assign({}, this.$route.query, {
          search: searchString || undefined,
        }),
      });
    }
  }

  private initEventContactTagsLocal(): void {
    const queryTagIds = this.queryTagIds;
    this.eventContactTagsLocal = (this.eventContactTags || []).map(tag => {
      return {
        tag,
        isSelected: queryTagIds.findIndex(id => id === tag.id) >= 0
      };
    });
  }

  // TODO: rename me on-based, i.e. onActiveTabUpdate
  public activeTab(tab: TTab, index: number): void {
    this.tabList.forEach((item, idx) => {
      item.isActive = idx === index;
    });

    this.redirectByActiveTab(this.tabList[index]);
  }

  public redirectByActiveTab(tab: TTab): void {
    this.$router.push(tab.index < 1 ? this.menuLocationTypeAll : this.menuLocationTypeMy).catch(() => { /* ignore */ });
  }

  private proceedToOwnBooth(): void {
    if (!this.myself || !this.myself.promopage_external_id) {
      return;
    }
    try {
      this.$router.push({
        name: 'promo-page-events-company',
        params: {
          external_id: this.myself.promopage_external_id
        }
      });
    } catch (e) {
      /* ignore */
    }
  }
}
